<script setup>
onMounted(() => {
  if (useAppConfig().ff.bot.globalBubble) {
    useSetTypebotVars()
    useLoadTypebot({})
  }
})
</script>

<template>
  <div>
    <!-- Bot embed -->
  </div>
</template>
